<script>
import Icon from '@/components/Icon.vue';
import { removeUserData } from '../helpers/user';

export default {
  components: {
    Icon
  },
  data() {
    return {
      user: {}
    };
  },
  methods: {
    signOut() {
      this.$store.commit('signOut');
      removeUserData();
    },
    isPjUser() {
      return this.user.cnpj && !this.user.cpf
    }
  },
  created() {
    setTimeout(() => {
      const userData = localStorage.getItem('user');
      if (userData !== null) {
        this.user = JSON.parse(userData);
      }
    }, 1500);
  }
};
</script>

<template>
  <div class="navigation">
    <div class="navigation__items">
      <router-link :to="{ name: 'my-cards' }" class="navigation__items__item" exact>
        <icon class="navigation__items__item__icon" name="cards"/>
        <span class="navigation__items__item__text">Meus cartões</span>
      </router-link>
      <router-link :to="{ name: 'banktransfer' }" class="navigation__items__item">
        <icon class="navigation__items__item__icon" name="teddoc"/>
        <span class="navigation__items__item__text">Transferência TED</span>
      </router-link>
      <!-- <router-link :to="{ name: 'transfer' }" class="navigation__items__item">
        <icon class="navigation__items__item__icon" name="transfers"/>
        <span class="navigation__items__item__text">Transferir</span>
      </router-link> -->
      <router-link :to="{ name: 'statementPayment' }" class="navigation__items__item" exact>
        <icon class="navigation__items__item__icon" name="payments"/>
        <span class="navigation__items__item__text">Boleto</span>
      </router-link>
      <router-link :to="{ name: 'cel-recharge' }" class="navigation__items__item">
        <icon class="navigation__items__item__icon" name="cellphone"/>
        <span class="navigation__items__item__text">Recarga de Celular</span>
      </router-link>
      <router-link v-if="!isPjUser()" :to="{ name: 'expenses' }" class="navigation__items__item">
        <icon class="navigation__items__item__icon" name="extract"/>
        <span class="navigation__items__item__text">Minhas Despesas</span>
      </router-link>
      <router-link v-if="isPjUser()" :to="{ name: 'extract' }" class="navigation__items__item">
        <icon class="navigation__items__item__icon" name="extract"/>
        <span class="navigation__items__item__text">Extrato</span>
      </router-link>
      <router-link :to="{ name: 'account' }" class="navigation__items__item" exact>
        <icon class="navigation__items__item__icon" name="account"/>
        <span class="navigation__items__item__text">Dados Pessoais</span>
      </router-link>
      <a @click="signOut" class="navigation__items__item navigation__items__item" exact>
        <icon class="navigation__items__item__icon" name="sign-out"/>
        <span class="navigation__items__item__text">Sair</span>
      </a>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.navigation {
  background-color: var(--color-white);
  border-top: 1px solid var(--color-grey-light);
  position: fixed auto 0 0 0;
  z-index: 999;
  @media (min-width: 992px) {
    border-top: none;
    border-left: .1875rem solid var(--color-grey-lighten);
    position: fixed 5rem auto 0;
    width: 14rem;
  }
  &__items {
    display: flex;
    @media (min-width: 992px) {
      padding-top: 2.3rem;
      display: block;
    }
    &__item {
      transition: 0.3s;
      color: var(--color-grey-dark);
      float: left;
      padding: 1rem 0;
      position: relative;
      text-align: center;
      width: 20%;
      @media (min-width: 992px) {
        display: block;
        float: none;
        margin-left: -.1875rem;
        padding-left: 1.5rem;
        text-align: left;
        width: 100%;
      }
      &--disabled {
        color: var(--color-grey-medium);
        cursor: default;
        ^&__text {
          text-decoration: line-through;
        }
      }
      &.on, &.on-exact, &:hover {
        /* color: var(--color-black); */
        color: var(--color-blue-onlypay);
        &:after {
          /* background-color: var(--color-green); */
          background-color: var(--color-blue-onlypay);
          content: '';
          height: .2475rem;
          position: absolute 0 0 0 0;
          @media (min-width: 992px) {
            position: absolute 0 auto 0 0;
            size: .1875rem auto;
          }
        }
      }
      &__icon {
        size: 1.25rem;
      }
      &__text {
        display: none;
        margin-left: 1rem;
        @media (min-width: 992px) {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
