var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "header header--small" }, [
      _c("div", { staticClass: "header__content row" }, [
        _c("h1", {
          staticClass: "header__content__heading col-12 pb-0",
          domProps: { innerHTML: _vm._s(_vm.$route.meta.title) },
        }),
        _vm.lastAccessUser
          ? _c("div", { staticClass: "header-last-access col-12" }, [
              _vm._v(
                "\n        Último acesso: " +
                  _vm._s(_vm.lastAccessUser) +
                  "\n      "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "header header--large" }, [
      _c(
        "div",
        { staticClass: "header__content" },
        [
          _c(
            "router-link",
            {
              staticClass: "header__content__logo",
              attrs: { to: { name: "my-cards" } },
            },
            [
              _c("v-icon", {
                staticClass: "header__content__logo__icon",
                attrs: { name: "logo" },
              }),
            ],
            1
          ),
          _vm.user
            ? _c(
                "router-link",
                {
                  staticClass: "header__content__account",
                  attrs: { to: { name: "account" } },
                },
                [
                  _c("div", { staticClass: "header__content__account__name" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.user.nome) + "\n          "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "header__content__account__avatar" },
                    [
                      _c("div", {
                        staticClass: "header__content__account__avatar__figure",
                        style: `background-image: url(${_vm.user.avatar})`,
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.lastAccessUser
            ? _c(
                "div",
                {
                  staticClass:
                    "header-last-access col-12 px-0 mx-0 row justify-content-end pt-2",
                },
                [
                  _vm._v(
                    "\n        Último Acesso: " +
                      _vm._s(_vm.lastAccessUser) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }