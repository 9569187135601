<script>
import VIcon from '@/components/Icon.vue';

export default {
  data() {
    return {
      lastAccessUser: null
    }
  },
  components: {
    VIcon,
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  async beforeMount() {
    await this.getLastAccessUser();
  },
  methods: {
    async getLastAccessUser() {
      await this.$http.get('/users/find-last-login')
        .then(({ data }) => {
          this.lastAccessUser = data.createdOn && this.$moment((data.createdOn || '').replace(/[Zz]/, '')).format('DD/MM/YYYY HH:mm:ss');
        })
    }
  }
};
</script>

<template>
  <div>
    <div class="header header--small">
      <div class="header__content row">
        <h1 v-html="$route.meta.title" class="header__content__heading col-12 pb-0"/>
        <div class="header-last-access col-12" v-if="lastAccessUser">
          Último acesso: {{lastAccessUser}}
        </div>
      </div>
    </div>
    <div class="header header--large">
      <div class="header__content">
        <router-link :to="{ name: 'my-cards' }" class="header__content__logo">
          <v-icon class="header__content__logo__icon" name="logo"/>
        </router-link>
        <router-link v-if="user" :to="{ name: 'account' }" class="header__content__account">
          <div class="header__content__account__name">
            {{user.nome}}
            <!-- <v-icon class="header__content__account__name__icon" name="arrow-down"/> -->
          </div>
          <div class="header__content__account__avatar">
            <div
              :style="`background-image: url(${user.avatar})`"
              class="header__content__account__avatar__figure"/>
          </div>
        </router-link>
        <div class="header-last-access col-12 px-0 mx-0 row justify-content-end pt-2" v-if="lastAccessUser">
          Último Acesso: {{lastAccessUser}}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.header {
  /* background-color: var(--color-blue-medium); */
  background-color: var(--color-blue-onlypay);
  &__content {
    @media (min-width: 992px) {
      margin: 0 auto;
      width: 960px;
    }
  }
  &--small {
    /* background-image: url('~@/assets/img/background.svg');
    background-position: bottom; */
    height: 14rem;
    @media (max-width: 720px) {
      height: 7.3rem;
    }
    @media (min-width: 992px) {
      background: none;
      height: auto;
      padding-top: 5rem;
    }
    ^&__content {
      @media (min-width: 992px) {
        padding-left: 17rem;
      }
      &__heading {
        color: var(--color-white);
        font-size: 1.5rem;
        font-weight: 300;
        padding: 2.5rem 1.5rem;
        @media (min-width: 992px) {
          color: var(--color-black);
          font-size: 2rem;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  &--large {
    display: none;
    position: fixed 0;
    size: 100% calc(5rem + 16px);
    z-index: 200;
    @media (min-width: 992px) {
      display: block;
    }
    ^&__content {
      clear: fix;
      padding: 1rem 0;
      &__logo {
        float: left;
        &__icon {
          color: var(--color-white);
          size: 6rem 3rem;
        }
      }
      &__account {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        float: right;
        &:hover {
          text-decoration: none;
        }
        &__name {
          color: var(--color-white);
          &__icon {
            color: var(--color-green-light);
            margin-left: .25rem;
            size: 1rem;
          }
        }
        &__avatar {
          /* background-image: linear-gradient(to right, #0080cd, #2bd21f); */
          background-image: linear-gradient(to right, var(--color-blue-medium), var(--color-green-onlypay));
          border-radius: 100%;
          margin-left: 1rem;
          padding: .0625rem;
          size: 3rem;
          &__figure {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            /* border: .125rem solid var(--color-blue-medium); */
            border: .125rem solid var(--color-blue-onlypay);
            border-radius: 100%;
            size: 2.875rem;
          }
        }
      }
    }
  }
  &-last-access {
    color: var(--color-white);
    @media (max-width: 991px) {
      text-align: end;
      padding-right: 5vw;
    }
  }
}
</style>
