<script>
/* eslint-disable */
import swal from 'sweetalert2';
import VButton from '@/components/Button.vue';
import VHeader from '@/components/Header.vue';
import VMenu from '@/components/Navigation.vue';
import { getUserData } from '../helpers/user';
import Conditions from '@/components/Conditions.vue';
import faq from '@/components/Faq.vue';

export default {
  components: {
    VButton,
    VHeader,
    VMenu,
    Conditions,
    faq
  },
  data() {
    return {
      showConsentTerm: false,
      showFaqModal: false
    }
  },
  beforeMount() {
    //let acceptTerms = localStorage.getItem('consent_terms');
    //this.showConsentTerm = (acceptTerms == null);
    // this.customVisibleByShowAlert();
  },
  mounted() {
    this.customVisibleByShowAlert();
  },
  methods: {
    accept() {
      localStorage.setItem('consent_terms', getUserData()._id)
      this.showConsentTerm = false
      //this.showAlert();
    },
    showAlert() {
      swal.fire({
        title: 'OnlyPay Informa',
        html: `<p style="text-align: left; font-size: 16px; margin-top:50px;margin-bottom:50px;">
          Durante o feriado de carnaval, nos dias 28/02/22 e 01/03/22 , não teremos expediente,
          retornaremos no dia 02/03/22 a partir das 12hs.<br>
          Fique atento aos serviços disponíveis na plataforma:
          <ol style="text-align:left;">
          <li>
            <strong>Pagamento de boleto e contas - </strong> Indisponível.
          </li>
          <li>
            <strong>Transferências bancárias - </strong> Indisponível.
          </li>
          <li>
            <strong>Recarga de Celular - </strong> Indisponível.
          </li>
          <li>
            <strong>Transferência Entre Cartões - </strong> Indisponível.
          </li>
          <li>
            <strong>Demais funcionalidades</strong> - Disponível.
          </li>
          </ol>
        <p>`,
        type: 'info',
        confirmButtonText: 'Ok'
      })
    },
    customVisibleByShowAlert () {
      // TODO: Nescessario preciso verificar os links dos termos de uso e politica de privacidade
      swal.fire({
        title: 'Política de Privacidade',
        html: `
        <p style="text-align: center; font-size: 18px;">
          Declaro que li e estou de acordo com o 
          <a href="#" target="_blank" style="text-decoration: underline; color: var(--dark);">
            Termo de Consentimento,
          </a>&nbsp;
          <a href="https://onlypay.com.br/politica-de-privacidade" target="_blank" style="text-decoration: underline; color: var(--dark);">
            Politica de Privacidade
          </a> e 
          <a href="https://conta.onlypay.com.br/#/termos-de-uso" target="_blank" style="text-decoration: underline; color: var(--dark);">
            Termos e Condições de Uso
          </a>
        <p>`,
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        // remove auto close by click outside
        allowOutsideClick: false
      })
      // await swal.fire(
      //   'AVISO!',
      //   `<p style="text-align:left;">
      //   Ola!<br><br>
      //   Devido ao feriado de Carnaval, nos dias 20 e 21 de fevereiro não haverá processamento bancário.<br><br>
      //   Equipe OnlyPay.
      //   </p>`,
      //   'info'
      // )
    }
  }
};
</script>

<template>
  <div class="signed-in">
    <v-header/>
    <div class="signed-in__content">
      <div class="signed-in__content__get-help-actions">
        <a class="whatsapp" href="https://wa.me/551138424693" title="Inicie uma conversa no WhatsApp" target="_blank">
          <img src="img/icon_whatsapp.svg" alt="" />
        </a>
        <a href="#" @click="showFaqModal = true" title="Dúvidas frequentes">
          <img src="/img/icon_question.svg" alt="" />
        </a>
      </div>
      <v-menu class="signed-in__content__menu"/>
      <router-view class="signed-in__content__view" />
    </div>
    <transition name="fade">
      <div class="wrapper-consent-terms" v-if="showConsentTerm">
        <div class="back-drop"></div>
        <conditions :showCloseBtn="false" @accept="accept" style="z-index:1000;" />
      </div>
    </transition>
    <transition name="fade">
      <faq @close="showFaqModal = false" style="z-index:1000;" v-if="showFaqModal" />
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.wrapper-consent-terms {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1000;

  .back-drop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.signed-in {
  &__content {
    /* padding-bottom: 3.5625rem; */
    @media (min-width: 992px) {
      margin: 0 auto;
      padding-bottom: 0;
      width: 960px;
    }

    &__get-help-actions {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 48px;
      /* height: 12vh; */
      /* padding-top: 0.5vh;
      padding-left: 0.2vw; */
      /* background: var(--color-blue-medium); */
      background: var(--color-blue-onlypay);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      position: fixed;
      top: 20vh;
      right: 0;
      z-index: 1030;
      padding: 0.9vh 0;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        transition: 0.2s;
        img { width: 42px; height: 42px; }

        &.whatsapp { img { width: 36px; height: 42px; } }

        &:hover { opacity: 0.7; }
      }

      @media (max-width: 991px) {
        top: 35vh;
      }

      @media (max-width: 575px) {
        top: 40vh;
      }
      @media (max-width: 550px) {
        display: none;
      }
    }

    &__view {
      margin-top: -7rem;
      padding: 0 1.5rem 3.5625rem;
      @media (max-width: 385px) {
        padding: 0 0rem 3.5625rem;
      }
      @media (min-width: 576px) {
        background-color: var(--color-white);
        /* padding-top: 1.5rem; */
      }
      @media (min-width: 992px) {
        margin-left: 17rem;
        margin-top: 0;
        padding: 0;
      }
      @media screen and (max-width: 991px) {
        padding-top: 5vh;
      }
    }
  }
  @media (min-width: 992px) {
    &__content__menu {
      top: calc(5rem + 16px);
    }
  }
}
@media (max-width: 720px) {
  .signed-in__content__view {
    margin-top: 0px!important;
  }
}
</style>