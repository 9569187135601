var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "signed-in" },
    [
      _c("v-header"),
      _c(
        "div",
        { staticClass: "signed-in__content" },
        [
          _c("div", { staticClass: "signed-in__content__get-help-actions" }, [
            _vm._m(0),
            _c(
              "a",
              {
                attrs: { href: "#", title: "Dúvidas frequentes" },
                on: {
                  click: function ($event) {
                    _vm.showFaqModal = true
                  },
                },
              },
              [_c("img", { attrs: { src: "/img/icon_question.svg", alt: "" } })]
            ),
          ]),
          _c("v-menu", { staticClass: "signed-in__content__menu" }),
          _c("router-view", { staticClass: "signed-in__content__view" }),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showConsentTerm
          ? _c(
              "div",
              { staticClass: "wrapper-consent-terms" },
              [
                _c("div", { staticClass: "back-drop" }),
                _c("conditions", {
                  staticStyle: { "z-index": "1000" },
                  attrs: { showCloseBtn: false },
                  on: { accept: _vm.accept },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.showFaqModal
            ? _c("faq", {
                staticStyle: { "z-index": "1000" },
                on: {
                  close: function ($event) {
                    _vm.showFaqModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "whatsapp",
        attrs: {
          href: "https://wa.me/551138424693",
          title: "Inicie uma conversa no WhatsApp",
          target: "_blank",
        },
      },
      [_c("img", { attrs: { src: "img/icon_whatsapp.svg", alt: "" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }